<template>
  <div>
    <!-- ANCHOR: Filters -->
    <PromotionAirlineFilters
      :type-filter.sync="typeFilter"
      :start-date.sync="startDate"
      :end-date.sync="endDate"
      :airline.sync="airline"
      @fetch-data="refetchData"
      @reset="clearFilter"
    />

    <!-- SECTION: Table Container Card -->
    <b-card
      no-body
      class="mb-0"
      style="max-height: 70vh"
    >
      <!-- SECTION Table Top -->
      <div class="mx-2 my-1">
        <b-row
          align-v="center"
          class="justify-content-md-between flex-md-nowrap"
        >

          <!-- ANCHOR size per page -->
          <b-col
            cols="4"
            md="auto"
            class="px-0 mr-md-1"
          >
            <v-select
              v-model="sizePerPage"
              :options="sizePerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template #option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <!-- <span class="text-muted ml-50">{{ $t('promotionAirline.promotions') }}</span> -->
          </b-col>

          <b-col
            cols="8"
            md="auto"
            class="d-flex justify-content-end px-50"
            order-md="3"
          >
            <div>
              <!-- ANCHOR button create -->
              <!-- :disabled="!canAccess('employee.createEmployee')" -->
              <b-button
                class="px-lg-1 px-sm-75"
                variant="info"
                @click="() => $router.push({name: 'apps-promotionAirline-create'})"
              >
                <span class="text-nowrap">
                  <feather-icon
                    class="cursor-pointer"
                    icon="PlusSquareIcon"
                    size="16"
                  />
                  <span class="d-none d-sm-inline ml-50">{{ $t('promotionAirline.create.title') }}</span>
                </span>
              </b-button>
            </div>
          </b-col>

        </b-row>
      </div>
      <!-- !SECTION -->

      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <!-- SECTION Table Content -->
        <b-table
          ref="refPromotionsListTable"
          style="max-height: 46vh"
          :sticky-header="true"
          class="position-relative"
          :items="fetchPromotionAirline"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('noMatchingResult')"
          :sort-desc.sync="isSortDirDesc"
          :sort-by.sync="sortBy"
        >

          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark text-nowrap"
            >
              {{ $t(`promotionAg.tableTitle.${data.label}`) }}
            </span>
          </template>

          <template #cell(checkbox)="data">
            <b-form-checkbox
              class="ml-2 mr-0 mt-50"
              name="check-box"
              inline
              :checked="isChecked(data.item.id)"
              @change="chooseItem(data.item.id)"
            />
          </template>

          <template #cell(no)="data">
            <div class="text-nowrap">
              <span class="font-weight-bold">
                {{ ((currentPage - 1) * sizePerPage) + data.index + 1 }}
              </span>
            </div>
          </template>

          <template #cell(code)="data">
            <div class="text-nowrap">
              <b-link
                :disabled="!canAccess('employee.detailEmployee')"
                :to="{
                  name: 'apps-promotionAirline-edit',
                  params: { id: data.item.id },
                }"
                class="font-weight-bold d-inline text-nowrap text-info"
              >
                <span
                  class="align-text-top text-capitalize font-weight-bold"
                  style="font-family: monospace;"
                >
                  {{ data.item.code }}
                </span>
              </b-link>
            </div>
          </template>

          <template #cell(agency)="data">
            <div class="text-nowrap">
              {{ data.item.agency }}
            </div>
          </template>

          <template #cell(discount)="data">
            <div class="text-right text-nowrap">
              {{ data.item.discountPercent || data.item.discountAmount }} {{ data.item.discountPercent > 0 ? '%' : 'VND' }}
            </div>
          </template>

          <template #cell(flightStartTime)="data">
            <div class="text-nowrap">
              <b>BĐ:</b> {{ data.item.flightStartTime && convertISODateTime(data.item.flightStartTime.substr(0, 10)).date }}
            </div>
            <div class="text-nowrap">
              <b>KT:</b> {{ data.item.flightEndTime && convertISODateTime(data.item.flightEndTime.substr(0, 10)).date }}
            </div>
          </template>

          <template #cell(startTime)="data">
            <div class="text-nowrap">
              <b>BĐ:</b> {{ data.item.startTime && convertISODateTime(data.item.startTime.substr(0, 10)).date }}
            </div>
            <div class="text-nowrap">
              <b>KT:</b> {{ data.item.endTime && convertISODateTime(data.item.endTime.substr(0, 10)).date }}
            </div>
          </template>

          <template #cell(quota)="data">
            <div class="text-nowrap">
              <b>Số tiền:</b> {{ data.item.appliedAmount }} / {{ data.item.quotaAmount }}
            </div>
            <div class="text-nowrap">
              <b>Số phiếu:</b> {{ data.item.appliedCounter }} / {{ data.item.quotaCounter }}
            </div>
          </template>

          <template #cell(active)="data">
            <b-form-checkbox
              v-model="data.item.active"
              class="mr-0 mt-50 custom-control-success"
              name="check-button"
              switch
              inline
              :disabled="data.item.active ? !canAccess('employee.activeEmployee') : !canAccess('employee.deactiveEmployee')"
              @click.native.prevent="changeActive(data.item)"
            />
          </template>

          <template #cell(action)="data">
            <b-button
              variant="danger"
              :disabled="!canAccess('customer.deleteCustomer')"
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                style="cursor: pointer"
              />
            </b-button>

          </template>

          <template #cell(createdAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ dateTime(data.item.createdAt) }}
                </h6>
                <small class="text-nowrap">
                  {{
                    data.item.createdBy
                      ? `${data.item.createdBy.firstName} ${data.item.createdBy.lastName}`
                      : ''
                  }}</small>
              </b-media-body>
            </b-media>
          </template>

          <template #cell(updatedAt)="data">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  rounded
                  variant="light-info"
                  size="34"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 text-nowrap">
                  {{ dateTime(data.item.updatedAt) }}
                </h6>
                <small class="text-nowrap">
                  {{
                    data.item.updatedBy
                      ? `${data.item.updatedBy.firstName} ${data.item.updatedBy.lastName}`
                      : ''
                  }}</small>
              </b-media-body>
            </b-media>
          </template>
        </b-table>
        <!-- !SECTION -->
      </b-overlay>

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Showing -->
          <b-col
            cols="12"
            sm="2"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-nowrap">{{ dataMeta.from }} {{ $t('paginationText.to') }} {{ dataMeta.to }}
              <span class="d-inline-block d-sm-none d-md-inline"> {{ $t('paginationText.outOf') }} </span>
              <span class="d-none d-sm-inline d-md-none"> / </span>
              {{ dataMeta.of }} {{ $t('paginationText.items') }}</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="8"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPromotions"
              :per-page="sizePerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BPagination,
  BMediaBody,
  BMediaAside,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  onUnmounted,
  ref,
} from '@vue/composition-api'

import store from '@/store'
import {
  sizePerPageOptions,
} from '@/constants/selectOptions'

import { avatarText, dateTime, convertISODateTime } from '@core/utils/filter'

import promotionAirlineStoreModule from '@promotionAirline/promotionAirlineStoreModule'
import usePromotionAirlineHandle from '@promotionAirline/usePromotionAirlineHandle'

import PromotionAirlineFilters from './filters.vue'

export default {
  components: {
    PromotionAirlineFilters,
    BMediaBody,
    BMediaAside,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BOverlay,

    vSelect,
  },
  setup() {
    const PROMOTION_AIRLINE_APP_STORE_MODULE_NAME = 'app-promotionAirline'

    // Register module
    if (!store.hasModule(PROMOTION_AIRLINE_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_AIRLINE_APP_STORE_MODULE_NAME, promotionAirlineStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_AIRLINE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROMOTION_AIRLINE_APP_STORE_MODULE_NAME)
      }
    })

    const {
      fetchPromotionAirline,
      tableColumns,
      sizePerPage,
      currentPage,
      totalPromotions,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refPromotionsListTable,
      refetchData,
      clearFilter,
      updateActive,
      deletePromotionAirline,

      // Extra Filters
      typeFilter,
      startDate,
      endDate,
      airline,

      genderFilter,
      searchTextFilter,
      statusFilter,
      createdByFilter,

      // Loading
      loading,

      isEmptyFilter,

    } = usePromotionAirlineHandle()

    const isAll = ref(false)
    const deleteIds = ref([])

    function changeActive(promotion) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.changeStatus'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            updateActive(promotion.id, !promotion.active)
          }
        })
    }

    function confirmDelete(id) {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('promotionAirline.delete.confirm') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'DELETE CONFIRM',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            deletePromotionAirline(id)
            this.deleteIds = []
          }
        })
    }

    function chooseItem(id) {
      this.deleteIds = this.setArray(this.deleteIds, id)
    }

    function setArray(array, id) {
      const index = array ? array.findIndex(element => element === id) : -1
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? array.splice(index, 1) : array.push(id)

      return array
    }

    function isChecked(id) {
      return this.deleteIds.includes(id)
    }

    function activeDelete() {
      return this.deleteIds.length === 0
    }

    // // ANCHOR: IMPORT MODAL
    // const showImportModal = ref(false)

    return {
      // empRole,
      isAll,
      deleteIds,
      chooseItem,
      isChecked,
      setArray,

      // Sidebar
      changeActive,
      updateActive,
      fetchPromotionAirline,

      activeDelete,
      confirmDelete,
      dateTime,
      convertISODateTime,
      tableColumns,
      totalPromotions,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refPromotionsListTable,
      refetchData,
      clearFilter,

      // Params
      sizePerPage, // to API: size
      currentPage, // to API: page

      // Filter
      avatarText,

      // select Options
      sizePerPageOptions,

      // Extra Filters
      typeFilter,
      startDate,
      endDate,
      airline,

      genderFilter,
      searchTextFilter,
      statusFilter,
      createdByFilter,

      // Loading
      loading,

      // Empty filter
      isEmptyFilter,

      // createdByOptions,
      // handleSearchCreatedBy,
      // fetchPromotionsByFilterSearch,
      // isLoading,
      // handleOpenCreatedBy,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  width: 100%;
  max-width: 90px;
  .vs__selected-options {
    flex-wrap: nowrap
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
